import React from 'react';
import './BouncingEllipsis.css';

const BouncingEllipsis: React.FC = () => {
  return (
    <div className="bouncing-ellipsis">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export default BouncingEllipsis;