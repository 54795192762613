import './ChatBot.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../config';
import BouncingEllipsis from './BouncingEllipsis';
import { FaCheckCircle, FaExclamationTriangle, FaTimesCircle, FaInfoCircle } from 'react-icons/fa';

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  type?: 'available' | 'similar' | 'taken' | 'normal';
  iconType?: 'check' | 'warning' | 'error' | 'info';
}

interface BusinessNameResponse {
  generatedNames: Array<{
    name: string;
    status: 'available' | 'similar' | 'taken';
    similarName?: string;
  }>;
  warning?: string;
}

const ChatBot: React.FC = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      role: 'assistant',
      content: "Describe your business and we'll generate some names for you!",
      type: 'normal',
      iconType: 'info'
    }
  ]);
  const [input, setInput] = useState('');
  const [country, setCountry] = useState('SG');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const savedMessages = Cookies.get('chatHistory');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
    
    // Get country from URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    const countryParam = urlParams.get('country');
    if (countryParam && ['SG', 'AU'].includes(countryParam.toUpperCase())) {
      setCountry(countryParam.toUpperCase());
    }
  }, []);

  useEffect(() => {
    console.log('Country updated:', country);
  }, [country]);

  useEffect(() => {
    Cookies.set('chatHistory', JSON.stringify(messages), { expires: 7 });
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getIconForMessage = (message: ChatMessage) => {
    switch (message.iconType) {
      case 'check':
        return <FaCheckCircle className="status-icon available" />;
      case 'warning':
        return <FaExclamationTriangle className="status-icon similar" />;
      case 'error':
        return <FaTimesCircle className="status-icon taken" />;
      case 'info':
      default:
        return <FaInfoCircle className="status-icon normal" />;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage: ChatMessage = { role: 'user', content: input };
    setMessages([...messages, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await axios.post<BusinessNameResponse>(
        `${config.apiUrl}/business-name/generate`,
        {
          description: input,
          country: country,
          count: 3,
        }
      );

      const { generatedNames, warning } = response.data;
      
      generatedNames.forEach((nameData) => {
        let content = '';
        let type: ChatMessage['type'] = 'normal';
        let iconType: ChatMessage['iconType'] = 'info';

        if (nameData.status === 'available') {
          content = `Company name idea: ${nameData.name}\nThis name is available`;
          type = 'available';
          iconType = 'check';
        } else if (nameData.status === 'similar') {
          content = `Company name idea: ${nameData.name}\nA similar name has been found (${nameData.similarName}). You could apply for this name, but it may be rejected`;
          type = 'similar';
          iconType = 'warning';
        } else if (nameData.status === 'taken') {
          content = `Company name idea: ${nameData.name}\nThis company name is already taken`;
          type = 'taken';
          iconType = 'error';
        }

        const nameMessage: ChatMessage = { 
          role: 'assistant', 
          content,
          type,
          iconType
        };
        setMessages(prevMessages => [...prevMessages, nameMessage]);
      });

      if (warning) {
        const warningMessage: ChatMessage = {
          role: 'assistant',
          content: `Warning: ${warning}`,
          type: 'normal',
          iconType: 'warning'
        };
        setMessages(prevMessages => [...prevMessages, warningMessage]);
      }
    } catch (error) {
      console.error('Error generating business name:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
      }
      const errorMessage: ChatMessage = {
        role: 'assistant',
        content: 'Sorry, there was an error generating the business names. Please try again.',
        type: 'normal'
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="chat-bot">
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role} ${message.type || ''}`}>
            {message.iconType && <span className="icon-wrapper">{getIconForMessage(message)}</span>}
            <span className="message-content">
              {message.content.split('\n').map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  {i < message.content.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
            </span>
          </div>
        ))}
        {isLoading && (
          <div className="message assistant">
            <BouncingEllipsis />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Describe your business!"
          disabled={isLoading}
        />
        <button type="submit" disabled={isLoading}>SEND</button>
      </form>
    </div>
  );
};

export default ChatBot;
