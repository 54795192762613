// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bouncing-ellipsis {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.dot {
  background-color: #333;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/BouncingEllipsis.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,aAAa;EACb,gDAAgD;AAClD;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".bouncing-ellipsis {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 20px;\n}\n\n.dot {\n  background-color: #333;\n  border-radius: 50%;\n  width: 8px;\n  height: 8px;\n  margin: 0 4px;\n  animation: bounce 1.4s infinite ease-in-out both;\n}\n\n.dot:nth-child(1) {\n  animation-delay: -0.32s;\n}\n\n.dot:nth-child(2) {\n  animation-delay: -0.16s;\n}\n\n@keyframes bounce {\n  0%, 80%, 100% {\n    transform: scale(0);\n  }\n  40% {\n    transform: scale(1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
