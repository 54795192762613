// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

main {
  flex: 1 1;
  display: flex;
  overflow: hidden;
  min-height: 0; /* This is crucial for nested flex containers */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,gBAAgB;EAChB,aAAa,EAAE,+CAA+C;AAChE","sourcesContent":["html, body, #root {\n  height: 100%;\n  margin: 0;\n  padding: 0;\n}\n\n.App {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 20px;\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\nmain {\n  flex: 1;\n  display: flex;\n  overflow: hidden;\n  min-height: 0; /* This is crucial for nested flex containers */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
